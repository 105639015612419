import {GetStaticPathsContext, GetStaticPathsResult, GetStaticProps} from 'next'
import {ParsedUrlQuery} from 'querystring'
import {SSRConfig} from 'next-i18next/dist/types/types'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {
  getCountries,
  getMapping,
  getOffices,
  getPage,
  getResourcesAlgolia,
  getTaxonomiesFilter,
   purgeNextjsStaticFiles,
} from '../lib/api'
import Layout from '../components/layout'
import Breadcrumb from '../components/shared/Breadcrumb'
import ContentTypeMapper from '../components/content-types/ContentTypeMapper'
import ParagraphMapper from '../components/paragraphs/ParagraphMapper'
import {
  Node,
  MenuLink,
  ResourceOverviewNode,
  OfficeNode,
  TaxonomyTerm,
  FooterMenuLink,
} from '../types/content-types'
import {Response} from '../types/api-types'
import {SearchResponse} from '@algolia/client-search'
import {getTranslation} from '../utils/get-translation'
import striptags from 'striptags'
import {SharedDataStrategy} from '../lib/cacheStrategies/sharedDataStrategy'
import {SharedDataRealtime} from '../lib/cacheStrategies/sharedDataStrategy.realtime'
import {
  APPLICATIONSTEPS_CACHEFILENAME,
  MENUS_CACHEFILENAME,
  OFFICES_CACHEFILENAME,
  saveToLocalizedCacheFile,
  TAXONOMIES_CACHEFILENAME,
} from '../lib/cacheFileHelper'
import {SharedDataRemoteCache} from '../lib/cacheStrategies/sharedDataStrategy.remoteCache'
import {getMenuFromDrupal} from '../lib/get-menus'
import {getDrupalCollection} from '../lib/redis'
import {getParams} from '../lib/get-params'
import {getDrupalResourceWithAdditionalPages} from '../lib/drupal'
import {getAllCountryPathsFromDrupal} from '../lib/get-paths-from-drupal'
import {getTranslations} from '../lib/get-dictionary-items'

export type PageProps = {
  page: Node | null
  breadCrumb: {label: string; path: string}[]
  mainMenuItems: MenuLink[] | null
  functionalNavItems: MenuLink[] | null
  socialMenuItems: MenuLink[] | null
  footerMenuItems: FooterMenuLink[] | null
  doormatMenuItems: MenuLink[] | null
  blockNewsResourcesAlgolia: SearchResponse | null
  siteSwitchMenuItems: MenuLink[] | null
  topNavigationMenuItems: MenuLink[] | null
  resources: SearchResponse | null
  country: string
  environment: string
  response: Response | null
  offices: OfficeNode[] | null
  officesResponse: Response | null
  countries: TaxonomyTerm[] | null
  regions: TaxonomyTerm[] | null
  domains: TaxonomyTerm[] | null
  expertiseDomains: TaxonomyTerm[] | null
  contractTypes: TaxonomyTerm[] | null
  languages: TaxonomyTerm[] | null
  functionsLevel: TaxonomyTerm[] | null
} & SSRConfig

interface Params extends ParsedUrlQuery {
  slug: string[]
}

function Page({
  page,
  breadCrumb,
  mainMenuItems,
  functionalNavItems,
  socialMenuItems,
  footerMenuItems,
  doormatMenuItems,
  blockNewsResourcesAlgolia,
  topNavigationMenuItems,
  resources,
  country,
  siteSwitchMenuItems,
  response,
  offices,
  officesResponse,
  countries,
  regions,
  contractTypes,
  domains,
  expertiseDomains,
  languages,
  functionsLevel,
}: PageProps) {
  const showBreadCrumb = page?.type !== 'node--search_page'
  return (
    <Layout
      mainMenuItems={mainMenuItems}
      functionalNavItems={functionalNavItems}
      doormatMenuItems={doormatMenuItems}
      socialMenuItems={socialMenuItems}
      footerMenuItems={footerMenuItems}
      siteSwitchMenuItems={siteSwitchMenuItems}
      topNavigationMenuItems={topNavigationMenuItems}
      page={page}
    >
      {showBreadCrumb && <Breadcrumb breadCrumb={breadCrumb} />}
      <ContentTypeMapper
        node={page}
        resources={resources}
        blockNewsResourcesAlgolia={blockNewsResourcesAlgolia}
        country={country}
        response={response}
        offices={offices}
        officesResponse={officesResponse}
        countries={countries}
        regions={regions}
        contractTypes={contractTypes}
        domains={domains}
        expertiseDomains={expertiseDomains}
        languages={languages}
        functionsLevel={functionsLevel}
      >
        {page?.relationships?.field_paragraphs?.data.map(paragraph => (
          <ParagraphMapper
            paragraph={paragraph}
            blockNewsResourcesAlgolia={blockNewsResourcesAlgolia}
            key={paragraph.id}
            country={country}
            response={response}
            expertiseDomains={expertiseDomains}
            regions={regions}
            functionsLevel={functionsLevel}
            languages={languages}
          />
        ))}
      </ContentTypeMapper>
    </Layout>
  )
}

export async function getStaticPaths(
  context: GetStaticPathsContext,
): Promise<GetStaticPathsResult> {
  {
    const localesWithoutDefault =
      context.locales?.filter(el => el !== 'default') ?? []

    if (process.env.LIGHTHOUSE_ACTIVE || process.env.APP_ENV === 'dev') {
      return {paths: [], fallback: 'blocking'}
    }

    if (
      process.env.NODE_ENV === 'production' &&
      process.env.APP_ENV !== 'dev'
    ) {
      for (let i = 0; i < localesWithoutDefault.length; i += 1) {
        const locale = localesWithoutDefault[i]
        const [menus, countryTaxonomies, offices, applicationSteps] =
          await Promise.all([
            getMenuFromDrupal({...context, locale}),
            getDrupalCollection(
              'taxonomy_term--country',
              getParams('taxonomy_term--country', locale).getQueryObject(),
            ),
            getDrupalResourceWithAdditionalPages(
              'group_content--site-group_node-office',
              locale,
            ),
            getDrupalResourceWithAdditionalPages(
              'node--application_process_step',
              locale,
            ),
          ])
        saveToLocalizedCacheFile(MENUS_CACHEFILENAME, menus, locale)
        saveToLocalizedCacheFile(
          `${TAXONOMIES_CACHEFILENAME}:country`,
          countryTaxonomies,
          locale,
        )
        saveToLocalizedCacheFile(OFFICES_CACHEFILENAME, offices, locale)
        saveToLocalizedCacheFile(
          APPLICATIONSTEPS_CACHEFILENAME,
          applicationSteps,
          locale,
        )
      }
    }

    const [pathsFromDrupal] = await Promise.all([
      getAllCountryPathsFromDrupal(),
      getTranslations(),
    ])

    const paths = (
      pathsFromDrupal?.filter(el => {
        if (!el.path) {
          return false
        }
        const splittedString = el.path?.split('/')
        if (!context.locales?.some(el => el === splittedString[1])) {
          return false
        }
        return true
      }) ?? []
    )
      .map(el => el.path)
      .map(pathFromDrupal => {
        const splittedString = pathFromDrupal.split('/')
        return {
          params: {
            slug:
              splittedString.slice(2)[0] === 'home'
                ? []
                : splittedString.slice(2),
          },
          locale: `${splittedString[1]}`,
        }
      })

    return {paths, fallback: 'blocking'}
  }
}

export const getStaticProps: GetStaticProps<
  PageProps,
  Params
> = async context => {
  const locale =
    context.locale === 'default' ? 'nl-be' : context.locale?.toLowerCase()
  let sharedDataStrategy: SharedDataStrategy
  if (process.env.NODE_ENV !== 'production') {
    // npm run dev
    sharedDataStrategy = new SharedDataRealtime()
  } else if (context.preview) {
    console.log("checking if this is a preview", context.preview)
    sharedDataStrategy = new SharedDataRealtime()
  } else {
    sharedDataStrategy = new SharedDataRemoteCache()
  }

  const {slug} = context.params as Params

  const [menus, _] = await Promise.all([
    sharedDataStrategy.getAllMenuLinks({...context, locale}),
    getTranslations(),
  ])

  const langCode = context.locale!.toLowerCase()
  const {t} = await getTranslation(context.locale as string, 'common')
  const applicationSlug = striptags(t('application_form_slug'))
    .replace(/\r\n/g, '')
    .toLowerCase()
  const openApplicationSlug = striptags(t('open_application_form_slug'))
    .replace(/\r\n/g, '')
    .toLowerCase()

  const jobSlug = striptags(t('job_detail_slug'))
    .replace(/\r\n/g, '')
    .toLowerCase()

  const officeSlug = striptags(t('officeoverview_offices'))
    .replace(/\r\n/g, '')
    .toLowerCase()

  const mapping = await getMapping(langCode, slug)

  if (
    !mapping &&
    slug &&
    slug[0] !== applicationSlug &&
    slug[0] !== openApplicationSlug &&
    slug[0] !== jobSlug &&
    slug[0] !== officeSlug
  ) {
    await purgeNextjsStaticFiles(langCode, context.params?.slug ?? [])
    return {
      notFound: true,
      revalidate: 60,
    }
  }
  let breadCrumb: {label: string; path: string}[] = []

  let response: Response | null = null
  let page: Node | null = null
  let countries: TaxonomyTerm[] | null = null
  let regions: TaxonomyTerm[] | null = null
  let contractTypes: TaxonomyTerm[] | null = null
  let domains: TaxonomyTerm[] | null = null
  let expertiseDomains: TaxonomyTerm[] | null = null
  let languages: TaxonomyTerm[] | null = null
  let functionsLevel: TaxonomyTerm[] | null = null

  if (mapping) {
    response = await getPage(
      context.preview && !mapping
        ? // @ts-ignore
          context.previewData?.id
        : mapping?.id,
      context.preview && !mapping
        ? // @ts-ignore
          context.previewData?.contentType
        : `node/${mapping?.type!}`,
      langCode,
      context.preview,
    )
    page = response?.included?.find(el => el.id === mapping.id) as Node
  } else if (slug && slug[0] === jobSlug && slug[1]) {
    const splittedSlug = slug[1].split('-')
    if (splittedSlug.length > 1) {
      const vacancyId = splittedSlug[0]
      response = await getPage(
        vacancyId,
        'node/vacancy',
        langCode,
        context.preview,
      )
      page = response?.data.find(
        vacancy => vacancy.attributes.field_vacancy_id === vacancyId,
      )
    }
  } else if (slug && slug[0] === officeSlug && slug[1]) {
    const splittedSlug = slug[1].split('-')
    if (splittedSlug.length > 1) {
      const officeId = splittedSlug[0]
      response = await getPage(
        officeId,
        'node/office',
        langCode,
        context.preview,
      )
      page = response?.included?.find(
        office => office?.attributes.field_id === officeId,
      )
    }
  } else if (slug === undefined) {
    response = await getPage(
      undefined,
      'node/homepage',
      langCode,
      context.preview,
    )
    page = (await response?.included?.find(
      el => el.type === 'node--homepage',
    )) as Node
  } else if (slug && slug[0] === openApplicationSlug) {
    page = {
      type: 'open-application-form',
    } as Node
    countries = await getCountries(langCode)
  } else if (slug && slug[0] === applicationSlug) {
    const vacancyId = slug[1]?.split('-')[0]
    response = await getPage(
      vacancyId,
      'node/vacancy',
      langCode,
      context.preview,
    )
    page = {
      ...response?.data.find(
        vacancy => vacancy.attributes.field_vacancy_id === vacancyId,
      ),
      type: 'application-form',
    }
    countries = await getCountries(langCode)
  }
  if (!page || (!page?.attributes?.status && slug[0] !== openApplicationSlug)) {
    await purgeNextjsStaticFiles(langCode, context.params?.slug ?? [])
    return {
      notFound: true,
      revalidate: 60,
    }
  }
  if (mapping) {
    breadCrumb = page?.attributes.breadcrumbs ?? []
    breadCrumb = breadCrumb.reverse()
  } else if (slug?.[0] === jobSlug) {
    breadCrumb.push({
      label: striptags(t('job_breadcrumb_title')),
      path: `/${jobSlug}`,
    })

    if (slug?.length > 1) {
      breadCrumb.push({
        path: `/${jobSlug}/${slug[1]}`,
        label: page?.attributes.title ?? '',
      })
    }
  } else if (slug?.[0] === officeSlug) {
    breadCrumb.push({
      path: `/${officeSlug}`,
      label: striptags(t('office_breadcrumb_title')),
    })

    if (slug?.length > 1) {
      breadCrumb.push({
        path: `/${officeSlug}/${slug[1]}`,
        label: page?.attributes.title ?? '',
      })
    }
  }

  let offices: OfficeNode[] | undefined = []
  let officesResponse: Response | null = null
  let resources: SearchResponse | null = null
  let blockNewsResourcesAlgolia: SearchResponse | null = null

  if (page?.type === 'node--job_overview') {
    regions = await getTaxonomiesFilter(langCode, 'region')
    domains = await getTaxonomiesFilter(langCode, 'domain')
    expertiseDomains = await getTaxonomiesFilter(langCode, 'expertise_domain')
    contractTypes = await getTaxonomiesFilter(langCode, 'contract_type')
    languages = await getTaxonomiesFilter(langCode, 'language_skill')
    functionsLevel = await getTaxonomiesFilter(langCode, 'function_level')
  }

  if (page?.type === 'node--homepage' || page?.type === 'node--content_page') {
    blockNewsResourcesAlgolia = await getResourcesAlgolia(
      langCode,
      4,
      null,
      'main_topic',
    )

    expertiseDomains = await getTaxonomiesFilter(langCode, 'expertise_domain')
  }

  if (page?.type === 'node--content_page') {
    regions = await getTaxonomiesFilter(langCode, 'region')
    functionsLevel = await getTaxonomiesFilter(langCode, 'function_level')
    languages = await getTaxonomiesFilter(langCode, 'language_skill')
  }

  if (page?.type === 'node--pillar_page') {
    resources = await getResourcesAlgolia(
      langCode,
      (page as ResourceOverviewNode).attributes.field_number_of_items ?? 6,
      page.attributes?.title,
    )
  }
  if (page?.type === 'node--resource_overview') {
    resources = await getResourcesAlgolia(
      langCode,
      (page as ResourceOverviewNode).attributes.field_number_of_items ?? 6,
    )
  }
  if (page?.type === 'node--office_overview') {
    officesResponse = await getOffices(langCode)
    offices = officesResponse?.included?.filter(
      el => el.type === 'node--office',
    )
  }

  const environment = process.env.APP_ENV!

  await getTranslations()
  return {
    props: {
      ...(await serverSideTranslations(context.locale!, ['common'])),
      page: page ?? null,
      response: response ?? null,
      breadCrumb,
      mainMenuItems: menus?.menus?.mainMenu ?? null,
      functionalNavItems: menus?.menus?.functionalMenu ?? null,
      socialMenuItems: menus?.menus?.socialMenu ?? null,
      doormatMenuItems: menus?.menus?.doormatMenu ?? null,
      footerMenuItems: menus?.menus?.footerMenu ?? null,
      siteSwitchMenuItems: menus?.menus?.siteSwitch ?? null,
      topNavigationMenuItems: menus?.menus?.topMenu ?? null,
      offices: offices ?? null,
      officesResponse,
      blockNewsResourcesAlgolia,
      resources,
      environment,
      country: process.env.COUNTRY!,
      countries,
      regions,
      contractTypes,
      domains,
      expertiseDomains,
      languages,
      functionsLevel,
    },
    revalidate: process.env.APP_ENV === 'prd' ? 1800 : 60,
  }
}

export default Page
